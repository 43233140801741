<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <!-- <div class="col-3">
            <c-label-text title="LBLPLANT" :value="chemData.plantName"></c-label-text>
          </div> -->
          <div class="col-3">
            <c-label-text title="품명" :value="chemData.mdmChemMaterialName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="자재코드" :value="chemData.materialCd"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="화학자재일련번호" :value="chemData.mdmChemMaterialId"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabheight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :param.sync="popupParam"
            :chemData.sync="chemData"
            :contentHeight="contentHeight"
            @getDetail="getDetail"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chem-msds',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        mdmChemMaterialMsdsId: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        preMdmChemMaterialId: '',  // 이전 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        deliveryVendorCd: '',  // 협력사명
        mfgVendorCd: '',  // 제조사명
        usageCd: null,  // 용도
        usageEtc: '',  // 용도 기타
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고환산계수
        inConversonUnitCd: '',  // 입고환산단위
        msdsNo: '',  // MSDS번호
        emergTel: '',  // 긴급연락처
        makeAddress: '',  // 제조사주소
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        colorClass: '',  // 색상
        warehousingTypeCd: null,  // 입고형태
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        explosionLimit: '',  // 폭발한계상한하한
        vaporPressure: '',  // 증기압
        twaStandart: '',  // 노출기준TWA
        characteristicValue: '',  // 특성치(경구경치흡입제외)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '', // 미사용 사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        depts: '', // 사용반
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      plantCd: '',
      plantName: '',
      materialCd: '',
      materialName: '',
      editable: true,
      tabheight: '',
      cheminfo: {
        col1: '사업장1',
        col2: 'CM-00000001',
        col3: '가성소다',
      },
      tab: 'chemMsdsFile',
      tabItems: [
        { name: 'chemMsdsFile', icon: 'drive_folder_upload', label: 'MSDS', component: () => import(`${'./chemMsdsFile.vue'}`) },
        { name: 'chemAppraiseFile', icon: 'drive_folder_upload', label: '유/위해성<br>평가 결과', component: () => import(`${'./chemAppraiseFile.vue'}`) },
        { name: 'chemProcess', icon: 'gavel', label: '취급공정', component: () => import(`${'./chemProcess.vue'}`) },
        // { name: 'chemQuantity', icon: 'tag', label: '정량값', component: () => import(`${'./chemQuantity.vue'}`) },
        { name: 'chemLaw', icon: 'gavel', label: '규제사항', component: () => import(`${'./chemLaw.vue'}`) },
        { name: 'chemPhoto', icon: 'photo', label: '제품사진', component: () => import(`${'./chemPhoto.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.tabheight = (window.innerHeight - 200) + 'px'
      if (this.popupParam.mdmChemMaterialId) {
        if (this.popupParam.subtabName) {
          this.tab = this.popupParam.subtabName;
        }
      }
    },
    getDetail(selectedId, check) {
      this.$emit('getDetail', selectedId, check)
    },
  }
};
</script>